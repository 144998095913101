// errorHandler.ts
import { Debugger } from './debugger';
import { PerformanceData, serializeError } from './errorSerializer';

export const enhancedErrorHandler = (event: ErrorEvent | PromiseRejectionEvent): object | null => {
  console.log('event', event);
  // Filter out events that only contain isTrusted information
  if (event instanceof ErrorEvent && 
      event.error && 
      typeof event.error === 'object' && 
      Object.keys(event.error).length === 1 && 
      'isTrusted' in event.error) {
    return null;
  }

  try {
    const baseErrorInfo = {
      timestamp: Date.now(),
      userAgent: window.navigator.userAgent,
      url: window.location.href,
      documentTitle: document.title,
      viewport: {
        width: window.innerWidth,
        height: window.innerHeight,
        pixelRatio: window.devicePixelRatio,
      },
      memory: (performance as any).memory ? {
        usedJSHeapSize: (performance as any).memory.usedJSHeapSize,
        totalJSHeapSize: (performance as any).memory.totalJSHeapSize
      } : undefined,
      network: {
        online: navigator.onLine,
        connection: (navigator as any).connection,
      }
    };

    // Only process errors that have meaningful information
    if (event instanceof ErrorEvent && event.message) {
      const errorInfo = {
        type: 'error',
        message: event.message,
        filename: event.filename,
        lineno: event.lineno,
        colno: event.colno,
        error: event.error ? serializeError(event.error) : null,
        stack: event.error?.stack,
        ...baseErrorInfo,
      };

      // Only return if there's meaningful error information
      return Object.values(errorInfo).some(value => value !== null && value !== undefined) 
        ? errorInfo 
        : null;
    }

    // Handle promise rejections similarly
    if (event instanceof PromiseRejectionEvent) {
      const reason = event.reason;
      
      // Skip if it's just a trusted event without additional data
      if (reason && typeof reason === 'object' && 
          Object.keys(reason).length === 1 && 
          'isTrusted' in reason) {
        return null;
      }

      return {
        type: 'unhandledrejection',
        reason: serializeError(reason),
        reasonType: reason ? typeof reason : 'undefined',
        reasonValue: reason instanceof Error ? reason.message : String(reason),
        reasonStack: reason instanceof Error ? reason.stack : undefined,
        ...baseErrorInfo,
      };
    }

    return null;

  } catch (error) {
    return {
      type: 'error_handler_failure',
      message: 'Error handler failed to process event',
      severity: Debugger.SEVERITY.CRITICAL,
      originalError: serializeError(error),
      timestamp: Date.now()
    };
  }
};