// debugger.ts
export const Debugger = {
    KEYS: {
      ERROR_HANDLER: 'ERROR_HANDLER',
      PROMISE_REJECTION: 'PROMISE_REJECTION',
      SERIALIZATION: 'SERIALIZATION',
      PERFORMANCE: 'PERFORMANCE',
      NETWORK: 'NETWORK',
      STATE: 'STATE',
      RESOURCE: 'RESOURCE'
    } as const,
  
    SEVERITY: {
      INFO: 'INFO',
      WARNING: 'WARNING',
      ERROR: 'ERROR',
      CRITICAL: 'CRITICAL'
    } as const,
  
    log(key: keyof typeof Debugger.KEYS, message: string, data?: any, severity?: keyof typeof Debugger.SEVERITY) {
      const timestamp = new Date().toISOString();
      const severityLabel = severity || 'INFO';
      
      console.group(`[${timestamp}] ${key} | ${severityLabel}`);
      console.log(`📝 Message: ${message}`);
      console.log(`🔔 Severity: ${severityLabel}`);
      
      if (data) {
        console.log('📊 Data:', data);
        if (data instanceof Error) {
          console.log('🔍 Stack:', data.stack);
          console.log('🔗 Cause:', data.cause);
        }
      }
      
      if (window.performance) {
        console.log('⚡ Performance:', {
          memory: (performance as any).memory,
          timing: performance.timing,
          navigation: performance.navigation
        });
      }
      
      console.groupEnd();
    },
  
    measurePerformance(label: string, callback: () => any) {
      const start = performance.now();
      const result = callback();
      const end = performance.now();
      const duration = end - start;
      
      this.log(
        this.KEYS.PERFORMANCE, 
        `${label} took ${duration.toFixed(2)}ms`,
        { duration, start, end }
      );
      
      return result;
    }
  };