// types/errorSerializer.types.ts

// Performance Monitoring Types
interface MemoryInfo {
  jsHeapSizeLimit: number;
  totalJSHeapSize: number;
  usedJSHeapSize: number;
}

interface PerformanceNavigation {
  redirectCount: number;
  type: number;
}

interface PerformanceTiming {
  connectEnd: number;
  connectStart: number;
  domComplete: number;
  domContentLoadedEventEnd: number;
  domContentLoadedEventStart: number;
  domInteractive: number;
  domLoading: number;
  domainLookupEnd: number;
  domainLookupStart: number;
  fetchStart: number;
  loadEventEnd: number;
  loadEventStart: number;
  navigationStart: number;
  redirectEnd: number;
  redirectStart: number;
  requestStart: number;
  responseEnd: number;
  responseStart: number;
  secureConnectionStart: number;
  unloadEventEnd: number;
  unloadEventStart: number;
}

interface PerformanceData {
  memory: MemoryInfo;
  navigation: PerformanceNavigation;
  timing: PerformanceTiming;
}

interface ApplicationInfo {
  application: string;
  version: string;
}

interface DebugMessage {
  timestamp: string;
  type: 'SERIALIZATION' | 'ERROR' | 'INFO' | 'WARNING';
  severity: 'INFO' | 'WARNING' | 'ERROR' | 'CRITICAL';
  data: ApplicationInfo;
  performance: PerformanceData;
}

// Error Serialization Types
interface SerializedError {
  name: string;
  message: string;
  stack?: string;
  cause?: object;
  timestamp: number;
  environment: 'browser' | 'server';
  context: {
    lastAction?: unknown;
    lastState?: unknown;
    componentStack?: string;
    route?: string;
    remixContext?: unknown;
    eventTrusted?: boolean;
  };
  browser?: {
    userAgent: string;
    language: string;
    platform: string;
    cookiesEnabled: boolean;
    viewport?: {
      width: number;
      height: number;
      pixelRatio: number;
    };
  };
  performance?: {
    memory?: MemoryInfo;
    navigationTiming?: PerformanceTiming;
    resources?: Array<{
      name: string;
      duration: number;
      type: string;
    }>;
  };
  additional?: Record<string, unknown>;
}

// Utility Functions
const isBrowser = typeof window !== 'undefined' && typeof navigator !== 'undefined';

const getBrowserInfo = () => {
  if (!isBrowser) return null;
  
  return {
    userAgent: navigator.userAgent,
    language: navigator.language,
    platform: navigator.platform,
    cookiesEnabled: navigator.cookieEnabled,
    viewport: {
      width: window.innerWidth,
      height: window.innerHeight,
      pixelRatio: window.devicePixelRatio,
    },
  };
};

const getPerformanceInfo = () => {
  if (!isBrowser || !window.performance) return null;

  return {
    memory: (performance as any).memory,
    navigationTiming: performance.timing,
    resources: Array.from(performance.getEntriesByType('resource')).map(entry => ({
      name: entry.name,
      duration: entry.duration,
      type: entry.entryType,
    })),
  };
};

// Debug Logger Class
class DebugLogger {
  private static instance: DebugLogger;
  private messages: DebugMessage[] = [];

  private constructor() {}

  static getInstance(): DebugLogger {
    if (!DebugLogger.instance) {
      DebugLogger.instance = new DebugLogger();
    }
    return DebugLogger.instance;
  }

  log(message: DebugMessage): void {
    this.messages.push(message);
    console.log(`[${message.timestamp}] ${message.type} | ${message.severity}`);
    console.log('📝 Message:', message.data);
    console.log('🔔 Severity:', message.severity);
    console.log('📊 Data:', message.data);
    console.log('⚡ Performance:', message.performance);
  }

  getMessages(): DebugMessage[] {
    return [...this.messages];
  }

  clearMessages(): void {
    this.messages = [];
  }
}

// Error Serializer
export const serializeError = (error: Error | unknown): SerializedError => {
  const logger = DebugLogger.getInstance();
  
  try {
    logger.log({
      timestamp: new Date().toISOString(),
      type: 'SERIALIZATION',
      severity: 'INFO',
      data: {
        application: 'entrycall-portal',
        version: '2.0.1'
      },
      performance: getPerformanceInfo() as unknown as PerformanceData
    });

    const baseError: SerializedError = {
      name: 'UnknownError',
      message: 'An unknown error occurred',
      timestamp: Date.now(),
      environment: isBrowser ? 'browser' : 'server',
      context: {
        route: isBrowser ? window.location.pathname : undefined,
        lastAction: isBrowser ? (window as any).__lastAction : undefined,
        lastState: isBrowser ? (window as any).__lastState : undefined,
      },
    };

    const browserInfo = getBrowserInfo();
    if (browserInfo) {
      baseError.browser = browserInfo;
    }

    const performanceInfo = getPerformanceInfo();
    if (performanceInfo) {
      baseError.performance = performanceInfo;
    }

    if (error && typeof error === 'object' && Object.keys(error).length === 1 && 'isTrusted' in error) {
      return {
        ...baseError,
        name: 'TrustedEvent',
        message: 'Trusted event without additional data',
        context: baseError.context,
        additional: {
          eventTrusted: 'isTrusted' in error ? (error as { isTrusted: boolean }).isTrusted : false,
          originalEvent: JSON.stringify(error)
        }
      };
    }

    if (error instanceof Error) {
      const errorProps = Object.getOwnPropertyNames(error).reduce((acc, key) => {
        try {
          const value = (error as any)[key];
          acc[key] = value instanceof Object ? JSON.stringify(value) : value;
          return acc;
        } catch (e) {
          acc[key] = '[Circular Reference]';
          return acc;
        }
      }, {} as Record<string, any>);

      return {
        ...baseError,
        name: error.name,
        message: error.message,
        stack: error.stack,
        cause: error.cause ? serializeError(error.cause) : undefined,
        context: {
          ...baseError.context,
          componentStack: (error as any).componentStack,
        },
        additional: errorProps,
      };
    }

    if (error instanceof PromiseRejectionEvent) {
      return {
        ...baseError,
        name: 'PromiseRejection',
        message: error.reason ? 
          (error.reason instanceof Error ? error.reason.message : String(error.reason)) 
          : 'Promise rejected without a reason',
        stack: error.reason?.stack,
        additional: {
          reason: error.reason instanceof Error ? 
            serializeError(error.reason) : 
            String(error.reason),
        },
      };
    }

    return {
      ...baseError,
      message: String(error),
      additional: {
        rawError: error instanceof Object ? JSON.stringify(error) : String(error),
      },
    };

  } catch (serializationError) {
    return {
      name: 'SerializationError',
      message: 'Failed to serialize error',
      timestamp: Date.now(),
      environment: isBrowser ? 'browser' : 'server',
      context: {},
      browser: getBrowserInfo() || undefined,
      additional: {
        originalError: String(error),
        serializationError: String(serializationError),
      },
    };
  }
};

export {
  MemoryInfo,
  PerformanceNavigation,
  PerformanceTiming,
  PerformanceData,
  ApplicationInfo,
  DebugMessage,
  SerializedError,
  DebugLogger
};