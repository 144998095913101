import { CoralogixConfig, CoralogixRumModule, LogEntry } from "../types";
import { initializeErrorHandlers } from "../utils/initialize";
import { serializeError } from "../utils/errorSerializer";

let coralogixPromise: Promise<CoralogixRumModule | null>;
let initializationPromise: Promise<void> | null = null;
let isInitialized = false;
const logQueue: LogEntry[] = [];
const MAX_QUEUE_SIZE = 1000;
const RETRY_ATTEMPTS = 3;
const RETRY_DELAY = 1000;

// Initialize the Coralogix promise based on environment
if (typeof window !== 'undefined') {
  // Load the CDN script if not already loaded
  if (!window.CoralogixRum) {
    const script = document.createElement('script');
    script.src = 'https://cdn.rum-ingress-coralogix.com/coralogix/browser/latest/coralogix-browser-sdk.js';
    script.async = true;
    document.head.appendChild(script);

    coralogixPromise = new Promise((resolve) => {
      script.onload = () => {
        resolve(window.CoralogixRum as CoralogixRumModule);
      };
      script.onerror = () => {
        console.error('Failed to load Coralogix CDN script');
        resolve(null);
      };
    });
  } else {
    coralogixPromise = Promise.resolve(window.CoralogixRum as CoralogixRumModule);
  }
} else {
  coralogixPromise = Promise.resolve(null);
}

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));





const validateConfig = (config: Partial<CoralogixConfig>): config is CoralogixConfig => {
  const requiredFields: (keyof CoralogixConfig)[] = ['public_key', 'application', 'version', 'coralogixDomain'];
  const missingFields = requiredFields.filter(field => !config[field]);
  
  if (missingFields.length > 0) {
    console.error(`Coralogix initialization failed: missing required fields: ${missingFields.join(', ')}`);
    return false;
  }
  return true;
};

const processLogQueue = async () => {
  const CoralogixRum = await coralogixPromise;
  if (!CoralogixRum) return;

  while (logQueue.length > 0) {
    const log = logQueue.shift();
    if (log && typeof CoralogixRum[log.level as keyof CoralogixRumModule] === 'function') {
      try {
        (CoralogixRum[log.level as keyof CoralogixRumModule] as Function)(
          log.message,
          { ...log.context, queuedAt: log.timestamp }
        );
      } catch (error) {
        console.error(`Failed to process queued log:`, error);
      }
    }
  }
};

export const initCoralogix = async (
  public_key: string = '',
  application: string = '',
  version: string = '',
  coralogixDomain: string = 'EU1'
): Promise<void> => {
  const config = { public_key, application, version, coralogixDomain };
  
  if (!validateConfig(config)) {
    return Promise.reject(new Error('Invalid Coralogix configuration'));
  }

  if (initializationPromise) {
    return initializationPromise;
  }

  initializationPromise = new Promise<void>(async (resolve, reject) => {
    if (isInitialized) {
      console.log('Coralogix RUM already initialized');
      resolve();
      return;
    }

    for (let attempt = 1; attempt <= RETRY_ATTEMPTS; attempt++) {
      try {
        const CoralogixRum = await coralogixPromise;
        if (!CoralogixRum) {
          throw new Error('CoralogixRum module not loaded');
        }

        CoralogixRum.init({
          ...config,
          instrumentations: {
            xhr: true,
            fetch: true,
            errors: true,
            custom: true
          }
        });

        // Initialize error handlers after successful initialization
        initializeErrorHandlers(CoralogixRum);
        
        isInitialized = true;
        console.log('Coralogix RUM initialized successfully');
        await processLogQueue();
        resolve();
        return;
      } catch (error) {
        console.error(`Initialization attempt ${attempt} failed:`, error);
        if (attempt === RETRY_ATTEMPTS) {
          reject(error);
          return;
        }
        await delay(RETRY_DELAY);
      }
    }
  });

  return initializationPromise;
};

const safeLog = async (level: string, message: string, context?: object): Promise<void> => {
  if (!message) {
    console.warn('Attempted to log empty message');
    return;
  }

  try {
    const CoralogixRum = await coralogixPromise;
    
    if (!isInitialized) {
      if (logQueue.length >= MAX_QUEUE_SIZE) {
        console.warn('Log queue is full, dropping oldest message');
        logQueue.shift();
      }
      logQueue.push({
        level,
        message,
        context: context ? serializeError(context) : undefined,
        timestamp: Date.now()
      });
      return;
    }

    if (CoralogixRum && typeof CoralogixRum[level as keyof CoralogixRumModule] === 'function') {
      (CoralogixRum[level as keyof CoralogixRumModule] as Function)(
        message,
        context ? serializeError(context) : undefined
      );
    }
  } catch (error) {
    console.error(`Failed to send ${level} log to Coralogix:`, error);
  }
};

export const logDebug = (message: string, context?: object) => safeLog('debug', message, context);
export const logInfo = (message: string, context?: object) => safeLog('info', message, context);
export const logWarn = (message: string, context?: object) => safeLog('warn', message, context);
export const logError = (message: string, context?: object) => safeLog('error', message, context);
export const logCritical = (message: string, context?: object) => safeLog('critical', message, context);

// Export error handling utilities
export const captureError = async (error: Error, context?: object) => {
  const CoralogixRum = await coralogixPromise;
  if (CoralogixRum && CoralogixRum.captureError) {
    CoralogixRum.captureError(error, context ? serializeError(context) : undefined);
  }
};

