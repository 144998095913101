import axiosInitializeInstance from "./axiosInstance_with_retry_mechanism.server";
import type { AxiosPublicRequestParams } from "../../types/index.server";
import { AxiosResponse, AxiosRequestConfig, AxiosError } from "axios";
import pLimit from "p-limit";

const MAX_CONCURRENT_REQUESTS = 5;
const REQUEST_TIMEOUT = 30000; // 30 seconds
const RETRY_ATTEMPTS = 3;
const MAX_BACKOFF_DELAY = 30000; // 30 seconds

const limit = pLimit(MAX_CONCURRENT_REQUESTS);

/**
 * Makes a public request using the configured Axios instance with retry and concurrency control.
 * @template T The type of the request data
 * @template R The type of the response data
 * @param {AxiosPublicRequestParams<T>} params - The parameters for the public request.
 * @returns {Promise<AxiosResponse<R>>} A promise that resolves with the response or rejects with an error.
 */
export const axiosPublicRequestWithRetryMechanism = <T = unknown, R = unknown>({
  url,
  method,
  config = {},
  data,
}: AxiosPublicRequestParams<T>): Promise<AxiosResponse<R>> => {
  return new Promise((resolve, reject) => {
    // Start the request process asynchronously
    setTimeout(() => {
      makeRequestWithRetry<T, R>({ url, method, config, data })
        .then(resolve)
        .catch(reject);
    }, 0);
  });
};

const makeRequestWithRetry = async <T = unknown, R = unknown>({
  url,
  method,
  config,
  data,
}: AxiosPublicRequestParams<T>): Promise<AxiosResponse<R>> => {
  return limit(async () => {
    let lastError;

    for (let attempt = 0; attempt < RETRY_ATTEMPTS; attempt++) {
      try {
        const instance = axiosInitializeInstance({
          ...config,
          timeout: REQUEST_TIMEOUT,
        });
        console.log(`Attempt ${attempt + 1} - Request Data:`, { url, method, data });

        const abortController = new AbortController();
        const requestConfig: AxiosRequestConfig = {
          url,
          method,
          ...config,
          data,
          signal: abortController.signal,
        };

        const response = await instance<R>(requestConfig);
        return response;
      } catch (error) {
        console.error(`Attempt ${attempt + 1} failed:`, error.message);
        lastError = error;

        if (error instanceof AxiosError && !shouldRetry(error)) {
          console.log("Error is not retryable, aborting retry attempts");
          throw error;
        }

        if (attempt < RETRY_ATTEMPTS - 1) {
          const backoffDelay = calculateBackoff(attempt);
          const delayWithJitter = addJitter(backoffDelay);
          console.log(`Retrying in ${delayWithJitter}ms`);
          await new Promise(resolve => setTimeout(resolve, delayWithJitter));
        }
      }
    }

    throw lastError;
  });
};

/**
 * Calculates the backoff delay using an exponential strategy.
 * @param {number} attempt - The current attempt number.
 * @returns {number} The calculated backoff delay in milliseconds.
 */
const calculateBackoff = (attempt: number): number => {
  return Math.min(Math.pow(2, attempt) * 1000, MAX_BACKOFF_DELAY);
};

/**
 * Adds jitter to the backoff delay to prevent request synchronization.
 * @param {number} delay - The initial delay.
 * @returns {number} The delay with added jitter.
 */
const addJitter = (delay: number): number => {
  return delay + (Math.random() * 1000);
};

/**
 * Determines if a retry should be attempted based on the error.
 * @param {AxiosError} error - The error from the failed request.
 * @returns {boolean} True if the request should be retried, false otherwise.
 */
const shouldRetry = (error: AxiosError): boolean => {
  if (error.response) {
    // Don't retry for client errors (4xx) except for 429 (Too Many Requests)
    return error.response.status === 429 || error.response.status >= 500;
  }
  // Retry for network errors or timeouts
  return true;
};

