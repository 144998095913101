import { CoralogixRumModule } from "../types";
import { captureError } from "../frontend/coralogix";
import { enhancedErrorHandler } from './errorHandler';

interface ImageErrorEvent extends ErrorEvent {
  target: HTMLImageElement;
  srcElement: HTMLImageElement;
}

export const initializeErrorHandlers = (CoralogixRum: CoralogixRumModule) => {
  if (typeof window === 'undefined') return;

  const errorQueue: Array<{ error: Error; metadata: any }> = [];
  let isProcessing = false;
  const processedErrors = new Set<string>();

  const shouldIgnoreError = (error: any): boolean => {
    if (!error) return true;
    
    return typeof error === 'object' && 
           ((Object.keys(error).length === 1 && 'isTrusted' in error) ||
           (error instanceof Event && error.type === 'error' && error.isTrusted));
  };

  const getImageErrorDetails = (event: ImageErrorEvent) => {
    const target = event.target as HTMLImageElement;
    return {
      src: target.src || 'unknown',
      className: target.className,
      naturalWidth: target.naturalWidth,
      naturalHeight: target.naturalHeight,
      currentSrc: target.currentSrc,
      loading: target.loading,
      complete: target.complete,
      timestamp: event.timeStamp,
      type: event.type,
      eventPhase: event.eventPhase,
      bubbles: event.bubbles,
      cancelable: event.cancelable
    };
  };

  const handleImageError = (event: ImageErrorEvent) => {
    const target = event.target as HTMLImageElement;
    const errorKey = `${target.src}_${event.timeStamp}`;
    
    if (processedErrors.has(errorKey)) return;
    processedErrors.add(errorKey);

    // Attempt to load fallback image
    const originalSrc = target.src;
    target.onerror = null; // Prevent infinite loop
    target.src = '/fallback-image.png';

    // Log the error details
    const errorDetails = getImageErrorDetails(event);
    CoralogixRum.error('Image Load Error', {
      ...errorDetails,
      originalSrc,
      fallbackApplied: true
    });

    // Clean up old processed errors after 5 minutes
    setTimeout(() => {
      processedErrors.delete(errorKey);
    }, 300000);
  };

  const isImageLoadError = (event: ErrorEvent): boolean => {
    console.log('isImageLoadError', event);
    return event.target instanceof HTMLImageElement && 
           (!event.error || shouldIgnoreError(event.error));
  };

  const processErrorQueue = async () => {
    console.log('processErrorQueue', errorQueue);
    if (isProcessing || errorQueue.length === 0) return;
    
    isProcessing = true;
    
    while (errorQueue.length > 0) {
      const { error, metadata } = errorQueue.shift()!;
      try {
        if (!shouldIgnoreError(error)) {
          await captureError(error, metadata);
        }
      } catch (e) {
        console.error('Failed to process error:', e);
        if (errorQueue.length < 100 && !shouldIgnoreError(error)) {
          errorQueue.push({ error, metadata });
        }
      }
    }
    
    isProcessing = false;
  };

  window.addEventListener('unhandledrejection', (event) => {
    console.log('unhandledrejection', event);
    if (shouldIgnoreError(event.reason)) return;

    const error = event.reason instanceof Error 
      ? event.reason 
      : new Error(String(event.reason || 'Unknown rejection reason'));

    errorQueue.push({
      error,
      metadata: {
        type: 'unhandledrejection',
        url: window.location.href,
        timestamp: Date.now()
      }
    });
    processErrorQueue();
    event.preventDefault();
  });

  window.addEventListener('error', (event: ErrorEvent) => {
    console.log('error', event);
    if (isImageLoadError(event)) {
      handleImageError(event as ImageErrorEvent);
      return;
    }

    if (!shouldIgnoreError(event.error)) {
      const errorDetails = enhancedErrorHandler(event);
      if (errorDetails) {
        CoralogixRum.error('Uncaught error', errorDetails);
      }
    }
  }, true);

  // Add global image error handler
  document.addEventListener('error', (event: Event) => {
    console.log('document error', event);
    if (event.target instanceof HTMLImageElement) {
      handleImageError(event as unknown as ImageErrorEvent);
    }
  }, true);

  const intervalId = setInterval(processErrorQueue, 5000);

  return () => {
    clearInterval(intervalId);
  };
};